import React, { useEffect, useState } from "react"
import "../style.scss"
import { Breadcrumb, Skeleton, Space, Spin } from "antd"

import moment from "moment"
import { getNewsDataByIdForRead } from "../../../services/news"

const ContentPageDetailNews = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [dataNews, setDataNews] = useState(null)

  const fetchDataNews = async () => {
    setLoading(true)
    const { response } = await getNewsDataByIdForRead(id)
    if (response.status == 200) {
      setLoading(false)
      setDataNews(response.data.news)
    }
  }

  const createMarkup = () => {
    return { __html: dataNews?.content }
  }
  useEffect(() => {
    if (id) {
      fetchDataNews()
    }
  }, [id])

  return (
    <div>
      <div className={"Content"}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Spin tip="Loading..." spinning={loading}>
            {loading && <Skeleton />}
          </Spin>
          {dataNews && (
            <div style={{ textAlign: "start" }}>
              <Space size={15}>
                <div className={"notifyDate"}>
                  {moment(dataNews.schedule).format("YYYY/MM/DD")}
                </div>
                <div
                  style={{
                    borderRadius: "3px",
                    background:
                      (dataNews.news_type == "news" && "#F0F5FF") ||
                      (dataNews.news_type == "notification" && "#FCEFDB") ||
                      "unset",
                    width: "fit-content",
                    padding: "0 10px",
                    color: "#333",
                  }}
                >
                  {dataNews.news_type == "news" && "ニュース"}
                  {dataNews.news_type == "notification" && "お知らせ"}
                </div>
              </Space>
              <h2 style={{ paddingLeft: "1rem" }}>{dataNews.title}</h2>
              <div style={{ padding: "0 20px", marginTop: 20 }}>
                <div
                  dangerouslySetInnerHTML={createMarkup()}
                  id="content-news "
                  className="text-[#333]"
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentPageDetailNews
